.component {
  align-items: center;
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: var(--size-32);
  padding: var(--size-40) 0;

  @media (--viewport-md) {
    flex-direction: row;
  }

  @media print {
    display: none !important;
  }
}

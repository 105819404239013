.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);
  padding: var(--size-48) 0;
}

.cardsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--size-40) var(--size-20);
}

.componentCard {
  display: flex;
  flex-direction: column;

  & > .imageContainer {
    width: 100%;
    margin-bottom: var(--size-20);
  }

  & > .name {
    margin-bottom: auto;
  }
}

.name {
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-500);
}

.cardDetail {
  font-size: var(--font-size-14);
}

.imageContainer {
  aspect-ratio: 1;
  border-radius: var(--radius-6);
  overflow: hidden;

  & > .image {
    width: 100%;
    height: 100%;
  }
}

.image {
  object-fit: cover;
}

.link {
  word-wrap: break-word;
  color: var(--color-blue-700);
  text-decoration: none;
  transition: color var(--duration-300) var(--ease-out);

  &:hover,
  &:focus {
    color: var(--color-orange-500);
  }
}

.component {
  & > *:not(:last-child) {
    margin-bottom: 50px;
  }

  & > .buttonContainer {
    width: 100%;
  }
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
}

.component {
  display: flex;
  align-items: center;
  gap: var(--size-12);
  padding: var(--size-12) var(--size-20);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-700);
  color: var(--color-white);
  background-color: var(--color-blue-500);
  border: 1px solid var(--color-blue-500);
  border-radius: var(--radius-4);
  transition: var(--duration-300) var(--ease-out);
  transition-property: color background-color;

  &:hover,
  &:focus {
    color: var(--color-blue-500);
    background-color: var(--color-white);
    border-color: var(--color-blue-500);
  }

  &:disabled {
    background-color: var(--color-grey-300);
    color: var(--color-white);
    border: var(--color-grey-300);
    pointer-events: none;
  }

  @media print {
    display: none !important;
  }
}

.component {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: var(--size-24);

  @media not (--viewport-sm) {
    padding-inline: var(--size-20);
  }

  @media (--viewport-md) {
    flex-wrap: nowrap;
  }

  & > .logoHomeLink {
    flex-grow: 1;

    @media (--viewport-md) {
      flex-grow: 0;
    }
  }

  & > .tabMenuLayout {
    order: 3;
    flex-basis: 100%;
    margin-top: var(--size-32);

    @media (--viewport-md) {
      margin-top: 0;
      order: initial;
    }
  }

  & > .languageSelectorLayout {
    margin-left: auto;

    @media (--viewport-md) {
      order: 3;
      margin: var(--size-4) 0;
    }
  }
}

.logoHomeLink {
  color: var(--color-white);
  font-size: var(--font-size-44);
  font-weight: var(--font-weight-700);

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.componentLanguageSelector {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--size-4);
  padding: var(--size-12) var(--size-12) var(--size-12) var(--size-40);
  color: var(--color-white);
  border: 1px solid var(--color-white);
  border-radius: var(--radius-6);
  transition: background-color var(--duration-300) var(--ease-out);

  @media print {
    display: none;
  }

  &:hover,
  &:focus {
    background-color: var(--color-white--10);
  }

  & > .select {
    position: absolute;
    inset: 0;
  }

  & > .iconContainer {
    width: max-content;
    height: var(--size-12);
  }
}

.select {
  position: relative;
  padding: 0 var(--size-12);
  appearance: none;
  background: none;
  border: none;
  color: currentColor;
  font-size: var(--font-size-12-14);
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }

  /* Removes the triangle on ≥IE10 */
  &::-ms-expand {
    display: none;
  }
}

.option {
  color: var(--color-blue-700);
}

.iconContainer {
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;

  & > .iconLayout {
    max-width: 100%;
    max-height: 100%;
  }
}

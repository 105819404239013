.component {
  margin: 0 auto;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  max-width: 978px;

  @media (--viewport-sm) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (--viewport-lg) {
    max-width: 978px;
    padding-top: 60px;
  }

  & > .childrenContainer {
    flex: 1 0 auto;
  }
}

.childrenContainer {
  background-color: var(--color-white);

  @media (--viewport-sm) {
    border-radius: var(--radius-6);
  }
}

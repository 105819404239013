.component {
  & > *:not(:last-child) {
    margin-bottom: var(--size-32);
  }
}

.actionsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--size-12);

  @media (--viewport-sm) {
    flex-direction: row;
    gap: var(--size-32);
  }

  & > *:not(:last-child) {
    @media (--viewport-sm) {
      margin-right: auto;
    }
  }

  & > *:only-child {
    @media (--viewport-sm) {
      margin-left: auto;
    }
  }
}

.componentResultsButton {
  border: none;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-400);
  text-decoration: underline;
  color: var(--color-blue-700);
  outline: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:disabled {
    color: var(--color-grey-300);
    pointer-events: none;
  }
}

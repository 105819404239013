.componentBase {
  display: flex;
  justify-content: center;

  & > * {
    max-width: 200px;
    width: 100%;
  }

  @media print {
    display: none;
  }
}

.componentTabBase {
  align-items: center;
  background: none;
  border: 1px solid transparent;
  cursor: pointer;
  display: flex;
  font-weight: var(--font-weight-700);
  justify-content: center;
  padding: var(--size-12) var(--size-4);
  transition: background-color var(--duration-300) var(--ease-out), color var(--duration-300) var(--ease-out);
  font-size: var(--font-size-12);

  @media (--viewport-md) {
    font-size: var(--font-size-14);
  }

  & > .icon {
    margin-right: var(--size-4);
  }

  &:first-child {
    border-radius: var(--radius-6) 0 0 var(--radius-6);
  }

  &:last-child {
    border-radius: 0 var(--radius-6) var(--radius-6) 0;
  }
}

.componentTabPrimary {
  color: var(--color-white);
  background-color: var(--color-blue-500);
  border-color: var(--color-white);

  &:hover,
  &:focus {
    background-color: var(--color-white--10);
  }

  &.selected {
    color: var(--color-blue-600);
    background-color: var(--color-white);
  }
}

.componentTabSecondary {
  color: var(--color-blue-700);
  border-color: var(--color-grey-500);

  &:hover,
  &:focus {
    background-color: var(--color-grey-100);
    border-color: var(--color-grey-600);
  }

  &.selected {
    background-color: var(--color-blue-700);
    border-color: var(--color-blue-700);
    color: var(--color-white);
  }
}

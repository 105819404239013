.component {
  padding: var(--size-20);
  background-color: var(--color-grey-200);
  border: 1px solid var(--color-grey-300);
  border-radius: var(--radius-6);
}

.componentPathWithoutMeds,
.legendItemWithoutMeds {
  fill: var(--color-blue-700);
}

.componentPathWithMeds,
.legendItemWithMeds {
  fill: var(--color-blue-500);
}

.component {
  & > *:not(:last-child) {
    margin-bottom: 30px;
  }
}

.patientInput {
  & > *:not(:last-child) {
    margin-bottom: 10px;

    @media (--viewport-sm) {
      margin-bottom: 30px;
    }
  }
}

.labelTooltipContainer {
  display: flex;
  align-items: center;
  gap: var(--size-4);
}

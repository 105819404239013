.component {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.5px;

  @media print {
    display: none !important;
  }
}

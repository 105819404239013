.componentAverage {
  stroke-dasharray: 0;
  stroke-dashoffset: 0;
  stroke: var(--color-blue-700);
  fill: none;

  &.isActive {
    animation: animateLine var(--duration-750) var(--ease-in-out) forwards;
    animation-delay: var(--duration-500);
  }
}

.componentFiveYearMark {
  opacity: 0;
  stroke: var(--color-blue-600--30);
  transition: opacity var(--duration-500) var(--ease-in-out);
  transition-delay: var(--duration-750);

  &.isActive {
    opacity: 1;
  }
}

.componentMarginOfError {
  opacity: 0;
  transition: opacity var(--duration-500) var(--ease-in-out);

  &.isActive {
    opacity: 1;
  }

  &.isDelayed {
    transition-delay: var(--duration-750);
  }
}

.marginOfErrorPath {
  stroke: var(--color-blue-700--40);
  fill: none;
}

.marginOfErrorFill {
  fill: var(--color-blue-500--20);
}

.treatmentPath {
  stroke: var(--color-blue-500);
  fill: none;

  &:nth-child(1) {
    stroke-dasharray: 0;
    stroke-dashoffset: 0;

    &.isActive {
      animation: animateLine var(--duration-750) var(--ease-in-out) forwards;
      animation-delay: var(--duration-500);
    }
  }
}

.gradientStop {
  stop-color: var(--color-blue-200);
  stop-opacity: 0;
  transition: stop-opacity var(--duration-500);
  transition-timing-function: var(--ease-out);

  &.isActive {
    stop-opacity: 1;
    transition-timing-function: var(--ease-in-out);
  }

  &:nth-child(2) {
    &.isActive {
      transition-delay: var(--duration-300);
    }
  }
}

@keyframes animateLine {
  to { stroke-dashoffset: 0; }
}

.componentSectionBasis,
.componentSectionResults {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);

  & > .graphsAndToggleLayout {
    margin-top: var(--size-32);
  }
}

.componentSectionResults {
  & > .animatedGraphTitleLayout {
    @media print {
      display: none !important;
    }
  }

  & > .predictionGraphsTitleLayout {
    margin-top: var(--size-32);
  }
}

.actionsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--size-12);

  @media (--viewport-sm) {
    flex-direction: row;
    gap: var(--size-32);
  }

  & > *:not(:last-child) {
    @media (--viewport-sm) {
      margin-right: auto;
    }
  }

  & > *:only-child {
    @media (--viewport-sm) {
      margin-left: auto;
    }
  }
}

.unorderedList {
  padding-left: var(--size-16);
}

.componentGraphsWithToggle {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);

  & > .toggleLayout {
    width: max-content;
    margin: 0 auto;
  }
}

.componentToggle {
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--size-16);
  padding: var(--size-8) var(--size-24);
  border-radius: var(--radius-25);
  color: var(--color-blue-700);
  background-color: var(--color-blue-300);
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
  transition: var(--duration-300) var(--ease-in-out);
  transition-property: opacity filter background-color;

  &:hover {
    background-color: var(--color-blue-500--40);
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.9;
    filter: grayscale(1);
  }

  & > .input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  & > .toggleIconLayout {
    height: var(--size-32);
  }
}

.input {
  opacity: 0;
  cursor: pointer;
}

.componentToggleIcon {
  opacity: 0.7;
  display: block;
  border-radius: var(--radius-25);
  aspect-ratio: 2 / 1;
  background-color: var(--color-grey-700);
  border: var(--size-4) solid var(--color-white);
  transition: var(--duration-300) var(--ease-in-out);
  transition-property: opacity background-color;
  pointer-events: none;

  &::before {
    content: '';
    display: block;
    aspect-ratio: 1;
    height: 100%;
    background-color: var(--color-white);
    background-clip: content-box;
    border: var(--size-4) solid transparent;
    border-radius: 50%;
    transform: translateX(0);
    transition: transform var(--duration-300) var(--ease-in-out);
  }

  &.isActive {
    opacity: 1;
    background-color: var(--color-green-600);

    &::before {
      transform: translateX(133%);
    }
  }
}

.graphsContentContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
}

.component {
  & > .title {
    margin-bottom: var(--size-8);
  }
}

.componentBase {
  list-style-type: disc;
  list-style-position: inside;

  & > * {
    margin-left: var(--size-24);
  }
}

.title {
  font-weight: var(--font-weight-700);
}

.listItem {
  list-style: disc;
}

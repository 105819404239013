.component {
  display: flex;
  align-items: center;
  gap: var(--size-16);

  & > .buttonLayout {
    width: var(--size-40);
    height: var(--size-40);
  }
}

.componentIndicators {
  display: flex;
  gap: var(--size-8);
  list-style: none;

  & > .indicator {
    width: var(--size-16);
    height: var(--size-16);
  }
}

.indicator {
  border-radius: 50%;
  background-color: var(--color-blue-600--20);
  transition: background-color var(--duration-100) var(--ease-in-out);

  &.isActive {
    background-color: var(--color-blue-700);
  }
}

.componentButtonBackward {
  --icon-rotation: 180deg;
}

.componentButtonBase {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--size-8);
  border-radius: 50%;
  color: var(--color-white);
  background-color: var(--color-blue-700);
  transition: var(--duration-100) var(--ease-in-out);
  transition-property: color background-color transform;

  &:disabled {
    color: var(--color-blue-700--40);
    background-color: var(--color-blue-600--20);
    pointer-events: none;
  }

  &:active {
    transform: scale(0.9);
  }

  & > .iconContainer {
    width: var(--size-16);
    height: var(--size-16);
  }
}

.iconContainer {
  transform: rotate(var(--icon-rotation));

  & > .iconLayout {
    width: 100%;
    height: 100%;
  }
}

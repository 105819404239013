@value transitionSpeed: 300ms; /* transitionSpeed is shared with js component */

.component {
  z-index: 0;
  position: relative;
  padding-bottom: var(--size-12);
  border-bottom: 1px solid var(--color-grey-300);

  & > .contentContainer {
    z-index: 1;
    width: 100%;
  }
}

.contentContainer {
  --opacity: 1;

  z-index: 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover,
  &:focus {
    --opacity: 0.6;
  }

  & > .label {
    z-index: 1;
  }

  & > .button {
    position: static;
  }
}

.label {
  pointer-events: none;
}

.button {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.iconContainer {
  opacity: var(--opacity);
  color: var(--color-blue-700);
  transition: transitionSpeed var(--ease-out);
  transition-property: opacity transform;

  &.isExpanded {
    transform: rotate(-180deg);
  }

  & > .iconLayout {
    width: var(--size-16);
    height: var(--size-16);
  }
}

.toggleContentEnterDone,
.toggleContentExitActive {
  transition: opacity transitionSpeed var(--ease-out), transform transitionSpeed var(--ease-out);
}

.toggleContentEnterDone,
.toggleContentExit {
  opacity: 1;
  transform: translateY(0);
}

.toggleContentExitActive,
.toggleContentEnter {
  opacity: 0;
  transform: translateY(-1 * var(--size-12));
}

.toggleContent {
  padding: var(--size-20) 0;
}

.component {
  padding: var(--size-20) var(--size-32) 0;

  @media (--viewport-md) {
    padding: var(--size-48) var(--size-40) 0;
  }
}

.componentWithImage {
  z-index: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  position: relative;
  border-radius: var(--radius-6) var(--radius-6) 0 0;

  &::after {
    content: '';
    z-index: -1;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.3) 60%);
    background: -ms-linear-gradient(bottom, rgba(0, 0, 0, 0.3) 0%, rgba(255, 255, 255, 0) 60%); /* IE11 fix */
    height: 75%;
    width: 100%;
  }

  & > * {
    grid-area: 1 / -1;
  }

  & > .bannerImage {
    z-index: -2;
    width: 100%;
    height: 290px;
  }
}

.bannerImage {
  object-fit: cover;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--size-20) var(--size-32);

  @media (--viewport-md) {
    padding: var(--size-48) var(--size-40) var(--size-24);
  }

  @media (--viewport-lg) {
    justify-content: flex-end;
  }

  & > .title {
    order: 1;

    @media (--viewport-lg) {
      width: 515px;
      align-self: flex-end;
    }
  }
}

.title {
  color: var(--color-white);
  font-size: var(--font-size-44);
  font-weight: var(--font-weight-500);
}

@value transitionSpeed: 300ms; /* transitionSpeed is shared with js component */

.component {
  & > .introduction {
    margin-bottom: var(--size-16);
  }

  & > *:last-child {
    margin-top: var(--size-48);
  }
}

.componentShowMoreContent {
  & > .showMoreEnterDone,
  & > .showMoreExitActive {
    transition: opacity transitionSpeed var(--ease-out), transform transitionSpeed var(--ease-out);
  }

  & > .showMoreEnterDone,
  & > .showMoreExit {
    opacity: 1;
    transform: translateY(0);
  }

  & > .showMoreExitActive,
  & > .showMoreEnter {
    opacity: 0;
    transform: translateY(-10px);
  }

  & > .showMoreContent {
    margin-top: 20px;
  }

  & > .showMoreButton {
    margin-top: 20px;
  }
}

.showMoreButton {
  cursor: pointer;
  font-size: var(--font-size-12);
  max-width: 75%;
  text-align: left;
}

.showMoreContent {
  & > ol {
    margin-bottom: var(--size-20);
    padding-left: 1.6rem;
  }
}

.component {
  & > .content {
    margin: 0 0 var(--size-20);
    width: 100%;

    &.editable {
      height: 320px;
    }
  }

  & > .buttonLayout {
    margin-bottom: var(--size-20);
  }
}

.content {
  background-color: var(--color-grey-100);
  border-radius: var(--radius-6);
  border: 1px solid var(--color-grey-300);
  color: var(--color-grey-700);
  font-family: inherit;
  padding: var(--size-24);
  white-space: pre-line;

  &.editable {
    background-color: var(--color-white);
    border-color: var(--color-grey-300);
    color: var(--color-blue-700);
    resize: vertical;

    @media print {
      display: none !important;
    }
  }

  @media print {
    border: none;
    padding: 0;
  }

  &.hideAndShowOnPrint {
    display: none;

    @media print {
      display: block;
    }
  }
}

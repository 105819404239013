.container {
  &:not(:last-child) {
    margin-bottom: 40px;
  }

  & > .detailComponent {
    margin-bottom: 10px;
  }

  & .proteinBlock {
    width: 50%;
  }

  & > .detailInfo {
    width: 100%;
  }

  & > .outcomeComponent {
    margin: 0 -10px;
  }

  & .outcomeBox {
    margin: 10px 10px 0;
    width: calc(100% / 3);
  }

  &.positive {
    & .detailComponent {
      border-color: var(--color-green-500);
    }

    & .outcomeBox::before {
      border-top-color: var(--color-green-500);
    }
  }

  &.negative {
    & .detailComponent {
      border-color: var(--color-red-500);
    }

    & .outcomeBox::before {
      border-top-color: var(--color-red-500);
    }
  }
}

.detailComponent {
  background-color: var(--color-grey-100);
  border-radius: var(--radius-6);
  border: 1px solid var(--color-grey-300);
  display: flex;
  flex-flow: row wrap;
  padding: 20px;

  & > .proteinBlock:not(:last-of-type)::after {
    background-color: var(--color-grey-300);
    content: '';
    height: calc(100% - 20px);
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
  }
}

.proteinBlock {
  display: flex;
  flex-direction: column;
  padding: 20px 0 30px;
  position: relative;
  text-align: center;
}

.proteinTotal {
  font-size: var(--font-size-34);
}

.proteinType {
  font-weight: var(--font-weight-500);
}

.outcomeComponent {
  display: flex;
  justify-content: center;

  & > .outcomeBox {
    min-height: 100px;
    width: 100%;
  }
}

.outcomeBox {
  align-items: center;
  background-color: var(--color-grey-100);
  border-radius: var(--radius-6);
  color: var(--color-grey-700);
  display: flex;
  font-size: var(--font-size-14);
  justify-content: center;
  padding: 20px 14px;
  position: relative;
  text-align: center;

  &.selected {
    background-color: var(--color-blue-700);
    color: var(--color-white);

    @media print {
      font-weight: var(--font-weight-700);
      color: var(--color-blue-700);
      border: 3px solid var(--color-blue-700);
      background-color: var(--color-white);
    }

    &::after,
    &::before {
      border: solid transparent;
      content: '';
      display: block;
      height: 0;
      left: 50%;
      position: absolute;
      top: -21px;
      transform: translateX(-50%);
      width: 0;
    }

    &::after {
      border-top-color: var(--color-grey-100);
      border-width: 10px;
    }

    &::before {
      border-top-color: var(--color-grey-400);
      border-width: 11px;
    }
  }
}

.component {
  align-items: center;
  background-color: var(--color-grey-100);
  border-radius: var(--radius-6);
  border: 1px solid var(--color-grey-200);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 30px 30px;

  @media print {
    /* Force printer to print background colors */
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    break-inside: avoid;
  }

  & > .icons,
  & > .bar {
    margin: 10px 0 30px;
  }

  & > .legend,
  & > .icons,
  & .legendText {
    width: 100%;
  }
}

.icons {
  margin: -3px;
  display: flex;
  flex-wrap: wrap;
}

.humanIconDarkContainer {
  color: var(--color-blue-700);
}

.humanIconLightContainer {
  color: var(--color-blue-500);
}

.humanIconDarkContainer,
.humanIconLightContainer {
  & > .iconLayout {
    height: 18px;
    width: 18px;
    margin: 3px;
  }
}

.bar {
  height: 100px;
  width: 40px;
  display: flex;
  flex-direction: column;

  & > *:first-child {
    border-radius: 3px 3px 0 0;
    background-color: var(--color-blue-700);
  }

  & > *:last-child {
    border-radius: 0 0 3px 3px;
    background-color: var(--color-blue-500);
  }
}

.portion {
  position: relative;

  & > * {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  & > .percentageFilled {
    right: 100%;
  }

  & > .percentageEmpty {
    left: 100%;
  }
}

.percentage {
  font-size: 12px;
  font-weight: var(--font-weight-500);

  &::before {
    content: '';
    height: 1px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.percentageFilled {
  padding-right: 15px;
  color: var(--color-blue-700);

  &::before {
    padding-right: inherit;
    background-color: var(--color-blue-700);
    right: 0;
  }
}

.percentageEmpty {
  padding-left: 15px;
  color: var(--color-blue-500);

  &::before {
    padding-left: inherit;
    background-color: var(--color-blue-500);
    left: 0;
  }
}


.legend {
  & > .legendRow:not(:last-child) {
    margin-bottom: 18px;
  }
}

.legendRow {
  display: flex;
  justify-content: space-between;

  @media (--viewport-md) {
    align-items: center;
  }

  & > .legendColorBox {
    margin-right: 14px;
  }

  &:last-of-type {
    color: var(--color-blue-500);

    & > .legendColorBox {
      background-color: var(--color-blue-500);
    }
  }
}

.legendColorBox {
  background-color: var(--color-blue-700);
  border-radius: 2px;
  height: 18px;
  width: 18px;
}

.legendText {
  font-size: var(--font-size-14);
  text-align: left;
}

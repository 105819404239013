.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);
  background-color: var(--color-blue-300);
  padding: var(--size-24);
  border-radius: var(--radius-12);
}

.title {
  font-size: var(--font-size-22);
  font-weight: var(--font-weight-700);
}

.content {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-8);
}

.componentPill {
  background-color: var(--color-white);
  border-radius: var(--radius-20);
  font-size: var(--font-size-14);
  padding: var(--size-16) var(--size-20);
  font-weight: var(--font-weight-400);
  color: var(--color-blue-700);
  line-height: 0.6;

  &:hover {
    background-color: var(--color-blue-700);
    color: var(--color-white);
  }

  &:active,
  &:visited,
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.component {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
  padding: var(--size-24) var(--size-20);
  border-radius: var(--radius-12);
  color: var(--color-blue-700);
  background-color: var(--color-blue-300);

  &.canBeDismissed {
    padding-right: var(--size-48);
  }

  & > .dismissButtonLayout {
    position: absolute;
    top: var(--size-12);
    right: var(--size-12);
  }
}

.componentDismissButton {
  padding: var(--size-12);
  border-radius: 50%;
  color: var(--color-blue-700);
  background-color: var(--color-blue-500--20);

  &:hover {
    background-color: var(--color-blue-500--40);
  }

  & > .iconLayout {
    width: var(--size-16);
    height: var(--size-16);
  }
}

.loader {
  align-items: center;
  background-color: none;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;

  & > .loaderIcon::after {
    width: 20px;
    height: 20px;
  }
}

.loaderIcon {
  display: inline-block;

  &::after {
    animation: icon-animation 1.2s linear infinite;
    border: 2px solid var(--color-orange-500);
    border-left-color: transparent;
    border-radius: 50%;
    border-right-color: transparent;
    content: '';
    display: block;
  }
}

@keyframes icon-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

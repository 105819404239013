.containerInfographic {
  display: flex;
  flex-direction: column;
  margin: -10px 0;

  @media (--viewport-sm) {
    flex-direction: row;
    align-items: flex-start;
    margin: -10px;
  }

  & > * {
    margin: 10px 0;

    @media (--viewport-sm) {
      margin: 10px;
      width: calc((100% - 60px) / 3);
    }
  }
}

.componentBase {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-weight: var(--font-weight-500);

  & > .fieldContainer {
    margin-top: auto;
  }
}

.patientInput {
  & > * {
    margin-bottom: var(--size-12);

    @media (--viewport-sm) {
      margin-bottom: var(--size-32);
    }
  }
}

.componentGroup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--size-12);
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: var(--size-8);
}

.groupTitle {
  font-size: var(--font-size-22);
  font-weight: var(--font-weight-500);
}

.groupContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: var(--size-20);

  @media (--viewport-sm) {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  & > * {
    flex: 1;
  }
}

.componentRadioGroup {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
}

.radioContainer {
  display: flex;
  gap: var(--size-16);
}

.componentInlineGroup {
  display: flex;
  align-items: flex-end;

  & > *:not(:last-child) {
    margin-right: var(--size-12);
  }
}

.componentInline {
  display: flex;
}

.legend {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-700);
}

.inlineLabel {
  display: inline-flex;
  align-items: center;
  padding-left: var(--size-12);
  font-size: var(--font-size-14);
}

.label {
  cursor: default;
  display: flex;
  gap: var(--size-4);
  padding-bottom: var(--size-12);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-500);

  & > .tooltipLayout {
    align-self: center;
    margin-left: var(--size-4);
  }
}

.labelAddition {
  font-size: var(--font-size-12);
  font-weight: var(--font-weight-400);
}

.componentNumber {
  padding: var(--size-12);
  border-radius: var(--radius-4);
  color: var(--color-blue-700);
  background-color: var(--color-blue-300--20);
  border: 1px solid var(--color-blue-700--40);
  font-weight: var(--font-weight-700);

  &::placeholder {
    color: var(--color-blue-700--40);
  }
}

.componentRadio,
.componentCheckbox {
  cursor: pointer;
}

.componentSelect {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: var(--size-12);
  border-radius: var(--radius-4);
  color: var(--color-blue-700);
  background-color: var(--color-blue-300--20);
  border: 1px solid var(--color-blue-700--40);
  font-weight: var(--font-weight-700);
  overflow: hidden;

  & > .select {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  & > .iconContainer {
    position: absolute;
    width: var(--size-48);
    height: 100%;
    right: 0;
    top: 0;
  }
}

.select {
  opacity: 0;
  cursor: pointer;
}

.placeholderText {
  &.noSelection {
    opacity: 0.3;
  }
}

.iconContainer {
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-blue-700);
  border-right: 1px solid var(--color-blue-700--40);
  background-color: var(--color-blue-100);
  transform: rotate(180deg);

  & > .iconLayout {
    width: var(--size-12);
    height: var(--size-12);
  }
}

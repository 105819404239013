.componentBase,
.componentLinkBase {
  padding: var(--size-12) var(--size-20);
  font-size: var(--font-size-14);
  border: 1px solid transparent;
  border-radius: var(--radius-4);
  transition: var(--duration-300) var(--ease-out);
  transition-property: color background-color;
  text-decoration: none;

  &:disabled {
    color: var(--color-white);
    background-color: var(--color-grey-300);
    border: var(--color-grey-300);
    pointer-events: none;
  }

  @media print {
    display: none !important;
  }
}

.componentLinkBase {
  display: inline-block;
}

.componentPrimary,
.componentLinkPrimary {
  font-weight: var(--font-weight-700);
  color: var(--color-white);
  background-color: var(--color-orange-500);
  border-color: var(--color-orange-500);

  &:hover,
  &:focus {
    color: var(--color-orange-500);
    background-color: var(--color-white);
  }
}

.componentPrimaryOutline {
  font-weight: var(--font-weight-400);
  color: var(--color-white);
  background-color: var(--color-orange-500);
  border-color: var(--color-white);

  &:hover,
  &:focus {
    color: var(--color-orange-500);
    background-color: var(--color-white);
    border-color: var(--color-orange-500);
  }
}

.componentSecondary {
  font-weight: var(--font-weight-700);
  color: var(--color-white);
  background-color: var(--color-blue-700);
  border-color: var(--color-blue-700);

  &:hover,
  &:focus {
    color: var(--color-blue-700);
    background-color: var(--color-white);
    border-color: var(--color-blue-700);
  }
}

.componentSecondaryOutline {
  font-weight: var(--font-weight-700);
  color: var(--color-blue-700);
  background-color: var(--color-white);
  border-color: var(--color-blue-700);

  &:hover,
  &:focus {
    color: var(--color-white);
    background-color: var(--color-blue-700);
  }
}

.componentTertiary {
  font-weight: var(--font-weight-700);
  color: var(--color-blue-700);
  background-color: var(--color-blue-300);
  border-color: var(--color-blue-300);

  &:hover,
  &:focus {
    background-color: var(--color-blue-500--40);
    border-color: var(--color-blue-500--40);
  }
}

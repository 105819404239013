:root {
  --size-4: 4px;
  --size-8: 8px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-64: 64px;
}

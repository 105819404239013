.component,
.componentTool {
  padding: var(--size-16) var(--size-24);
  border-radius: var(--radius-25);
  color: var(--color-blue-700);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-700);
  list-style: none;
  text-align: center;
  text-decoration: none;
  background: none;
  outline: none;
  transition:
    background-color var(--duration-300) var(--ease-out),
    color var(--duration-300) var(--ease-out);
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: var(--color-blue-500--40);
  }

  &.isCurrent,
  &.isActive {
    background-color: var(--color-blue-700);
    color: var(--color-white);
  }

  &:disabled {
    background: none;
    opacity: 0.4;
    cursor: default;
  }

  @media (--viewport-lg) {
    border-radius: 0 var(--radius-25) var(--radius-25) 0;
  }
}

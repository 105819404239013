.component {
  border-radius: var(--radius-6);
  border: 1px solid var(--color-grey-300);
  padding: 20px;

  & > .label {
    margin-top: 10px;
  }

  & > .chart {
    margin: auto;
  }
}

.primaryColor {
  & .circleBg {
    stroke: var(--color-blue-300);
  }

  & .circle {
    stroke: var(--color-blue-500);
  }
}

.secondaryColor {
  & .circleBg {
    stroke: var(--color-orange-300);
  }

  & .circle {
    stroke: var(--color-orange-500);
  }
}

.tertiaryColor {
  & .circleBg {
    stroke: var(--color-blue-300--20);
  }

  & .circle {
    stroke: var(--color-blue-700);
  }
}

.disabledColor {
  & .circleBg {
    stroke: var(--color-grey-100);
  }

  & .circle {
    stroke: var(--color-grey-300);
  }
}

.chart {
  display: block;
  width: 100%;
  max-height: 125px;

  @media (--viewport-sm) {
    max-height: 250px;
  }
}

.circleBg {
  fill: none;
  stroke-width: 4;
}

.circle {
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  transition: 1s stroke-dasharray ease-out;
}

.percentage {
  fill: currentColor;
  font-size: 0.8rem;
  text-anchor: middle;

  &.disabledColor {
    color: var(--color-grey-300);
  }
}

.label {
  text-align: center;

  &.disabledColor {
    color: var(--color-grey-300);
  }
}

.component {
  padding: var(--size-32) 0;

  & > *:not(:last-child) {
    margin-bottom: var(--size-20);
  }
}

.componentGroup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (--viewport-lg) {
    flex-direction: row;
  }

  & > * {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: var(--size-20);

      @media (--viewport-lg) {
        margin-bottom: 0;
        margin-right: var(--size-20);
      }
    }
  }
}

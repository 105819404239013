.privacyStatement {
  & > .privacyStatementContent {
    margin-top: 20px;
  }

  & ol {
    margin-bottom: var(--size-20);
    padding-left: 1.6rem;
  }
}

.component {
  & > *:not(:last-child) {
    margin: var(--size-20) 0;
  }
}

.results {
  & > *:not(:last-child) {
    margin-bottom: var(--size-12);
  }
}

.component,
.content {
  display: flex;
  flex-direction: column;
}

.component {
  gap: var(--size-16);
}

.content {
  gap: var(--size-8);
}

.pros {
  color: var(--color-green-500);
}

.cons {
  color: var(--color-red-500);
}

.title {
  font-weight: var(--font-weight-700);
}

.component {
  padding: var(--size-20);
  background-color: var(--color-grey-200);
  border: 1px solid var(--color-grey-300);
  border-radius: var(--radius-6);
}

.componentPathTreatment,
.legendItemTreatment {
  stroke: var(--color-blue-500);
}

.componentPathAverage,
.legendItemAverage {
  stroke: var(--color-blue-700);
}

.componentPathBase {
  fill: none;
}

.marginOfErrorPath {
  stroke: var(--color-blue-700--40);
  fill: none;
}

.marginOfErrorFill {
  fill: var(--color-blue-500--20);
}

.legendItemMarginOfError {
  stroke: var(--color-blue-700--40);
  fill: var(--color-blue-500--20);
}

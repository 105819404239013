.axisStroke {
  stroke: var(--color-blue-700--40);
  stroke-dasharray: 0;
  stroke-width: 0.1;
}

.axisLabel {
  font: bold 0.5px sans-serif;
  fill: var(--color-blue-700);
}

.axisTitle {
  font: bold 0.6px sans-serif;
  fill: var(--color-blue-700);
}

.component,
.componentTools {
  z-index: 0;
  position: relative;

  @media print {
    display: none !important;
  }

  & > .menuButton {
    z-index: 2;
    position: relative;
    width: 100%;
  }

  & > .list {
    z-index: 1;
    position: relative;
  }
}

.list {
  display: flex;
  flex-direction: column;
  padding: var(--size-20);
  background-color: var(--color-blue-300);
  border-radius: 0 0 var(--radius-6) var(--radius-6);

  @media (--viewport-lg) {
    border-radius: var(--radius-6);
    padding: var(--size-20) var(--size-20) var(--size-20) 0;
  }

  & > * {
    width: max-content;
  }
}

.componentTools {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
}

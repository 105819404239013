/* Fonts */
:root {
  --font-family-base: 'Rawline', sans-serif;

  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-700: 700;

  /* Sizes */
  --font-size-unit: 1px;
  --font-size-viewport-min: 480;
  --font-size-viewport-max: 1920;
  --font-size-growth: calc(1 / (var(--font-size-viewport-max) - var(--font-size-viewport-min)));
  --m: calc((var(--font-size-growth) * 100vw) - (var(--font-size-growth) * var(--font-size-viewport-min) * var(--font-size-unit)));

  --font-size-5: 5px;
  --font-size-10: 10px;
  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-22: 22px;
  --font-size-34: 34px;
  --font-size-44: 44px;

  --font-size-12-14: clamp(12px, calc(12px + 2 * var(--m)), 14px);
}

.component {
  display: inline-flex;
  flex-direction: column;
  gap: var(--size-8);

  & > * {
    margin-bottom: var(--size-8);

    &:has(+ .headingLayout) {
      margin-bottom: var(--size-24);
    }
  }

  & > .headingLayout {
    &:has(+ *) {
      margin-bottom: 0;
    }
  }
}

.componentBase {
  display: flex;
  flex-direction: column;

  & > .label {
    margin-bottom: var(--size-12);
  }

  & > .textArea {
    min-height: 170px;
  }

  & > .errorMessageLayout {
    margin-top: var(--size-4);
  }
}

.label {
  font-weight: var(--font-weight-700);
}

.componentText,
.componentTextArea {
  padding: var(--size-12);
  border: 1px solid var(--color-blue-600--30);
  border-radius: var(--radius-4);
  background-color: var(--color-blue-300--20);
  outline: none;

  &::placeholder {
    color: var(--color-blue-700--40);
  }

  &:active,
  &:focus {
    border: 1px solid var(--color-blue-600);

    &::placeholder {
      opacity: 1;
    }
  }
}

.componentTextArea {
  resize: vertical;
}

.inputError {
  border: 1px solid var(--color-red-500);
  color: var(--color-blue-700);
}

.componentError {
  color: var(--color-red-500);
  font-size: var(--font-size-14);
}

.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-8);
}

.componentItem {
  background-color: var(--color-blue-300);
  border-radius: var(--radius-6);
}

.icon {
  line-height: 0;
  transform: translateY(-50%);
  transform-origin: center 0%;

  & > .iconLayout {
    width: var(--size-16);
    height: var(--size-16);
  }

  &.isOpen {
    transform: rotate(180deg) translateY(-50%);
  }
}

.summary {
  padding: var(--size-24) var(--size-64);
  position: relative;
  text-align: center;
  cursor: pointer;
  list-style: none;
  font-weight: var(--font-weight-700);

  &::-webkit-details-marker {
    display: none;
  }

  & > .icon {
    position: absolute;
    top: 50%;
    right: var(--size-32);
  }
}

.contentContainer {
  padding: 0 var(--size-32) var(--size-32);
}

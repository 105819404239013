.component {
  z-index: 0;
  position: relative;

  @media (--viewport-lg) {
    display: none;
  }

  @media print {
    display: none !important;
  }

  & > .buttonLayout {
    z-index: 2;
    position: relative;
    width: 100%;
  }

  & > .listLayout {
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
  }
}

.componentButton {
  display: flex;
  align-items: center;
  padding: var(--size-20);
  background-color: var(--color-white);
  border-radius: var(--radius-6);
  cursor: pointer;
  color: var(--color-blue-700);
  border: 1px solid var(--color-grey-300);

  &.isActive {
    & .buttonIconBar {
      background-color: transparent;
      transform: rotate(180deg);

      &::before {
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
      }

      &::after {
        bottom: 50%;
        transform: translateY(50%) rotate(-45deg);
      }
    }
  }

  & > .buttonIcon {
    width: var(--size-24);
    height: var(--size-20);
  }

  & > .buttonLabel {
    flex-grow: 1;
  }

  & > .buttonIcon {
    position: absolute;
    top: 50%;
    left: var(--size-20);
    transform: translateY(-50%);
  }
}

.buttonIcon {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.buttonIconBar,
.buttonIconBar::before,
.buttonIconBar::after {
  width: 100%;
  height: 2px;
  background-color: var(--color-blue-700);
  border-radius: 3px;
  transition: background-color 200ms linear, transform var(--duration-300) var(--ease-out);
}

.buttonIconBar {
  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
  }

  &::before {
    transform: translateY(calc(-50% - 8px)) rotate(0);
    top: 50%;
  }

  &::after {
    transform: translateY(calc(50% + 8px)) rotate(0);
    bottom: 50%;
  }
}

.buttonLabel {
  font-weight: var(--font-weight-700);
}

.componentList {
  align-items: center;
  background-color: var(--color-blue-300);
  border-radius: 0 0 var(--radius-6) var(--radius-6);
  display: flex;
  flex-direction: column;
  padding: var(--size-20);
}

.listEnter {
  opacity: 0;
  transform: translateY(var(-1 * var(--size-48)));
}

.listEnterActive {
  opacity: 1;
  transform: translateY(var(-1 * var(--size-4)));
  transition: var(--duration-300) opacity var(--ease-out), var(--duration-300) transform var(--ease-out);
}

.listExit {
  opacity: 1;
  transform: translateY(var(-1 * var(--size-4)));
}

.listExitActive {
  opacity: 0;
  transform: translateY(var(-1 * var(--size-48)));
  transition: var(--duration-300) opacity var(--ease-out), var(--duration-300) transform var(--ease-out);
}

:root {
  --color-white--10: rgba(255, 255, 255, 0.1);
  --color-white: #fff;

  --color-grey-100: #f5f5f5;
  --color-grey-200: #fcfcfc;
  --color-grey-300: #e6e6e6;
  --color-grey-400: #d8d8d8;
  --color-grey-500: #c2c2c2;
  --color-grey-600: #bababa;
  --color-grey-700: #808080;

  --color-blue-100: #f9fdff;
  --color-blue-200: #e8f8ff;
  --color-blue-300--20: rgba(222, 245, 255, 0.2);
  --color-blue-300: #def5ff;
  --color-blue-500--20: rgba(108, 195, 232, 0.2);
  --color-blue-500--40: rgba(108, 195, 232, 0.4);
  --color-blue-500: #6cc3e8;
  --color-blue-600--20: rgba(32, 98, 174, 0.2);
  --color-blue-600--30: rgba(32, 98, 174, 0.3);
  --color-blue-600: #2062ae;
  --color-blue-700--40: rgba(50, 76, 142, 0.4);
  --color-blue-700: #324c8e;

  --color-green-500: #75b94e;
  --color-green-600: #69ac27;

  --color-orange-300: #fce4d0;
  --color-orange-500: #f97122;

  --color-red-500: #e6000f;
}

:root {
  --duration-100: 100ms;
  --duration-300: 300ms;
  --duration-500: 500ms;
  --duration-750: 750ms;
  --duration-1500: 1500ms;
  --duration-2000: 2000ms;
  --duration-2500: 2500ms;
  --ease-out: ease-out;
  --ease-in-out: ease-in-out;
}

@value transitionSpeed: 300ms;

.component {
  --circle-size: var(--size-40);
  --circle-white-space: var(--size-32);

  counter-reset: accordion;
  list-style: none;
  position: relative;

  & > * {
    margin-bottom: 0 0 var(--size-20) var(--size-16);
  }

  &::before {
    /* background dotted border with controlled spacing and size */
    background-image: radial-gradient(circle at 2px, var(--color-grey-400) 1px, transparent 1px);
    background-position: left;
    background-repeat: repeat-y;
    background-size: 4px 13px;
    content: '';
    height: calc(100% - var(--circle-size));
    left: calc(var(--circle-size) / 2 - 3px);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
}

.componentItem {
  position: relative;

  &::before {
    align-items: center;
    background-color: var(--color-blue-300);
    border-radius: 50%;
    content: counter(accordion);
    counter-increment: accordion;
    display: flex;
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-700);
    height: var(--circle-size);
    justify-content: center;
    left: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: var(--size-16);
    transition: background-color transitionSpeed var(--ease-out), color transitionSpeed var(--ease-out);
    width: var(--circle-size);
  }

  &::after {
    background-color: var(--color-grey-300);
    bottom: 0;
    content: '';
    height: 1px;
    left: calc(var(--circle-size) + var(--circle-white-space));
    position: absolute;
    width: calc(100% - (var(--circle-size) + var(--circle-white-space)));
  }

  &.isExpanded::before {
    background-color: var(--color-blue-700);
    color: var(--color-white);
  }
}

.componentButton {
  background: none;
  color: var(--color-blue-700);
  cursor: pointer;
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-700);
  padding: var(--size-24) 0 var(--size-24) calc(var(--circle-size) + var(--circle-white-space));
  text-align: left;
  width: 100%;
}

.contentContainer {
  padding-bottom: var(--size-24);
  padding-left: calc(var(--circle-size) + var(--circle-white-space));
}

.orderedAccordionContentEnterDone,
.orderedAccordionContentExitActive {
  transition: opacity transitionSpeed var(--ease-out), transform transitionSpeed var(--ease-out);
}

.orderedAccordionContentEnterDone,
.orderedAccordionContentExit {
  opacity: 1;
  transform: translateY(0);
}

.orderedAccordionContentExitActive,
.orderedAccordionContentEnter {
  opacity: 0;
  transform: translateY(-1 * var(--size-12));
}

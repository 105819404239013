.component {
  display: flex;
  gap: var(--size-16);
  flex-direction: column;

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > .imageWrapper {
    @media (--viewport-md) {
      max-width: 200px;
    }
  }
}

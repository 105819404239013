.tooltip {
  &.isDelayed {
    transition-delay: var(--duration-1500);
  }

  & > .label {
    max-width: 140px;
  }

  @media print {
    display: none !important;
  }
}

.label {
  padding: var(--size-12);
  border-radius: var(--radius-6);
  color: var(--color-white);
  background-color: var(--color-blue-600);
  text-align: center;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-500);
  line-height: 1.25;
}

.floatingArrow {
  fill: var(--color-blue-600);
}

.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);
}

.componentItem {
  display: flex;
  align-items: center;
  gap: var(--size-8);
  color: var(--color-blue-700);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-300);
  line-height: 1;
}

.component {
  & > .additionalTestText {
    margin-bottom: 50px;
  }

  & > .resultBlock {
    margin-bottom: 50px;
  }
}

.resultBlock {
  & >.image {
    margin: 10px auto 30px;
  }
}

.image {
  border-radius: var(--radius-6);
}

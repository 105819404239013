.component {
  & > .loaderLayout {
    height: 150px;
    left: 50%;
    position: fixed;
    top: 50%;
    width: 150px;
  }
}

.componentLoader {
  transform: translate(-50%, -50%);
}

.circleBg {
  fill: none;
  stroke-width: 2;
  stroke: rgba(255, 255, 255, 0.1);
  animation: pulse var(--duration-2500) var(--duration-1500) var(--ease-in-out) infinite;
}

.circle {
  fill: none;
  stroke-linecap: round;
  stroke-width: 0.5;
  stroke: var(--color-white);
  animation: loading var(--duration-1500) cubic-bezier(0.86, 0, 0.07, 1) forwards;
}

.text {
  fill: var(--color-white);
  font-size: var(--font-size-5);
  opacity: 0;
  text-anchor: middle;
  transform-origin: center;
  animation: fade-in var(--duration-2000) var(--duration-500) cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
}

@keyframes loading {
  0% {
    stroke-dasharray: 0 100;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    stroke: rgba(255, 255, 255, 0.1);
  }

  50% {
    stroke-width: 4;
    stroke: rgba(255, 255, 255, 0.6);
  }

  100% {
    stroke: rgba(255, 255, 255, 0.1);
  }
}

.componentContainer {
  display: flex;
  flex-direction: column;
  column-gap: var(--size-32);

  @media (--viewport-lg) {
    display: grid;
    grid-template-columns: 295px 1fr;
    grid-template-areas:
      'header header'
      'menu content';
    row-gap: var(--size-40);
    justify-content: space-between;
  }

  & > * {
    width: 100%;
  }

  & > .headerLayout {
    grid-area: header;
  }

  & > .desktopMenuLayout {
    grid-area: menu;
    margin-bottom: var(--size-48);

    @media (--viewport-lg) {
      margin: 0 0 var(--size-40) var(--size-40);
    }
  }

  & > .contentLayout {
    grid-area: content;
  }
}

.componentContent {
  padding: var(--size-40) var(--size-32) var(--size-64);

  @media (--viewport-md) {
    padding: var(--size-48) var(--size-48) var(--size-64);
  }

  @media (--viewport-lg) {
    padding: 0 var(--size-48) var(--size-64);
  }

  & > * {
    width: 100%;
  }

  & > .tag {
    width: max-content;
    margin-bottom: var(--size-12);
  }
}

.tag {
  padding: var(--size-8) var(--size-16);
  border-radius: var(--radius-25);
  color: var(--color-blue-700);
  background-color: var(--color-blue-300);
  font-size: var(--font-size-12);
  font-weight: var(--font-weight-500);
}

.subtitle {
  padding-bottom: var(--size-24);
  font-weight: var(--font-weight-700);
}

.title {
  padding-bottom: var(--size-32);
  font-size: var(--font-size-44);
  font-weight: var(--font-weight-400);
}

.intro {
  padding-bottom: var(--size-24);
  font-size: var(--font-size-20);
}
